import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { FaEdit, FaUser } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RootState } from "../redux/store";
import {
  fetchPartyFromSearch,
  getPartyList,
  handlePartyListActions,
} from "../services/axios.services";

interface Party {
  _id: string;
  name: string;
  email: string;
  image: string;
  partyType: string;
  phone: string;
  isActive: boolean;
}

export default function PartyList() {
  const token = useSelector((state: RootState) => state.user.token);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [allParty, setAllParty] = useState<Party[]>([]);
  const [selectedId, setSelectedId] = useState<string>("");
  const [typeOfAction, setTypeOfAction] = useState<string>("");

  const navigate = useNavigate();

  const fetchParty = async () => {
    const partyList = await getPartyList(token as string);
    console.log("all authors -> ", partyList);
    setAllParty(partyList);
  };

  useEffect(() => {
    fetchParty();
  }, []);

  const columns: ColumnDef<Party, any>[] = [
    {
      header: "Image",
      cell: ({ row }) =>
        row.original.image ? (
          <img
            src={`${process.env.REACT_APP_BASE_URL}/${row.original.image}`}
            alt={`image`}
            width={50}
            height={50}
          />
        ) : (
          <FaUser size={40} />
        ),
    },
    {
      header: "Name",
      cell: ({ row }) => (
        <Box>
          <Text>{row.original.name}</Text>
        </Box>
      ),
    },
    {
      header: "Email & Phone",
      cell: ({ row }) => (
        <Box>
          <Text>{row.original.email}</Text>
          <Text>{row.original.phone}</Text>
        </Box>
      ),
    },
    {
      header: "Type",
      accessorKey: "partyType",
    },
    {
      header: "Action",
      accessorKey: "isActive",
      cell: ({ row }) => (
        <Flex gap={2} justifyContent={"center"}>
          <IconButton
            ml={1}
            aria-label="Edit Product"
            icon={<FaEdit />}
            onClick={() => {
              navigate(`/edit-party/${row.original._id}`);
            }}
          />
          {row.getValue("isActive") ? (
            <Button
              colorScheme="red"
              onClick={() => {
                onOpen();
                setSelectedId(row.original._id ?? "");
                setTypeOfAction("Make Inactive");
              }}
            >
              Make Inactive
            </Button>
          ) : (
            <Button
              colorScheme="green"
              onClick={() => {
                onOpen();
                setSelectedId(row.original._id ?? "");
                setTypeOfAction("Make Active");
              }}
            >
              Make Active
            </Button>
          )}
        </Flex>
      ),
    },
  ];

  const table = useReactTable({
    data: allParty,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  const handleActions = async () => {
    const reqBody = {
      id: selectedId,
      isActive: typeOfAction === "Make Inactive" ? "false" : "true",
    };

    console.log(reqBody);
    const response = await handlePartyListActions(reqBody, token as string);
    if (response?.success) {
      toast.success(response.message);
      fetchParty();
      onClose();
    }
  };

  const [searchValue, setSearchValue] = useState<string>("");

  const handleSearch = async () => {
    const response = await fetchPartyFromSearch(
      {
        value: searchValue,
      },
      token as string
    );
    console.log("response -> ", response);
    if (response.success) {
      setAllParty(response.data);
      // let pages;
      // if (response.data.total % 50 == 0) pages = response.data.total / 50;
      // setTotalPages(Math.ceil(response.data.total / 50));
    }
  };

  return (
    <Box>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Heading as={"h3"} size={"lg"} my={5} ml={5}>
          Party List
        </Heading>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{typeOfAction}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to {typeOfAction} this Party?
            </ModalBody>

            <ModalFooter gap={3}>
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="blue" mr={3} onClick={handleActions}>
                Confirm
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Box my={"auto"}>
          <Input
            type="text"
            w={"350px"}
            placeholder="Search party by name or phone"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Button ml={3} mt={-1.5} mr={2} onClick={handleSearch}>
            Search
          </Button>
        </Box>
      </Box>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  <div onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: " 🔼",
                      desc: " 🔽",
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
}
