import {
  Box,
  Button,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { TranslatorRegistrationFormData } from "../interfaces/Translator.interface";
import { RootState } from "../redux/store";
import { handleAuthorOrTranslatorRegistration } from "../services/axios.services";

export default function TranslatorRegistration() {
  const token = useSelector((state: RootState) => state.user.token);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<TranslatorRegistrationFormData>({});

  const [profilePicture, setProfilePicture] = useState("");
  const [nidFront, setNidFront] = useState("");
  const [nidBack, setNidBack] = useState("");

  const handleImageUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setter(reader.result as string);
      };
    }
  };

  const onSubmit = async (data: TranslatorRegistrationFormData) => {
    const reqBody: TranslatorRegistrationFormData = {
      nameBangla: data.nameBangla,
      nameEnglish: data.nameEnglish,
      email: data.email,
      password: data.password,
      confirmPassword: data.confirmPassword,
      phone: data.phone,
      nidNumber: data.nidNumber,
      facebookProfile: data.facebookProfile,
      facebookBusiness: data.facebookBusiness,
      referenceId: data.referenceId,
      presentAddress: data.presentAddress,
      permanentAddress: data.permanentAddress,
      nidPictureFront: nidFront,
      nidPictureBack: nidBack,
      profilePicture: profilePicture,
    };

    const response = await handleAuthorOrTranslatorRegistration(
      reqBody,
      "translators",
      token as string
    );
    console.log("res -> ", response);
    if (response?.success) {
      toast.success(response.message);
      reset();
      setProfilePicture("");
      setNidFront("");
      setNidBack("");
    } else toast.error(response?.data.message);
  };

  const password = watch("password");

  return (
    <Box p={8}>
      <Heading as={"h3"} size={"lg"} mb={5}>
        Translator Registration
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateColumns={"1fr 1fr 1fr"} gap={5}>
          <GridItem>
            <FormLabel>
              Translator Name (Bangla)
              <Text as="span" color={"red.400"}>
                *
              </Text>
            </FormLabel>
            <Input
              type="text"
              {...register("nameBangla", {
                required: "Bangla name is required",
                maxLength: {
                  value: 80,
                  message: "Maximum length is 80 characters",
                },
              })}
            />
            {errors.nameBangla && (
              <Text color={"tomato"}>{errors.nameBangla.message}</Text>
            )}
          </GridItem>
          <GridItem>
            <FormLabel>
              Translator Name (English)
              <Text as="span" color={"red.400"}>
                *
              </Text>
            </FormLabel>
            <Input
              type="text"
              {...register("nameEnglish", {
                required: "English name is required",
                maxLength: {
                  value: 80,
                  message: "Maximum length is 80 characters",
                },
              })}
            />
            {errors.nameEnglish && (
              <Text color={"tomato"}>{errors.nameEnglish.message}</Text>
            )}
          </GridItem>
          <GridItem>
            <FormLabel>
              Email
              <Text as="span" color={"red.400"}>
                *
              </Text>
            </FormLabel>
            <Input
              type="email"
              {...register("email", {
                required: "Email is required",
              })}
            />
            {errors.email && (
              <Text color={"tomato"}>{errors.email.message}</Text>
            )}
          </GridItem>
          <GridItem>
            <FormLabel>
              Phone
              {/* <Text as="span" color={"red.400"}>
                *
              </Text> */}
            </FormLabel>
            <Input
              type="number"
              {...register("phone", {
                // required: "Phone is required",
              })}
            />
            {/* {errors.phone && (
              <Text color={"tomato"}>{errors.phone.message}</Text>
            )} */}
          </GridItem>
          <GridItem>
            <FormLabel>
              NID
              {/* <Text as="span" color={"red.400"}>
                *
              </Text> */}
            </FormLabel>
            <Input
              type="number"
              {...register("nidNumber", {
                // required: "NID is required",
              })}
            />
            {/* {errors.nidNumber && (
              <Text color={"tomato"}>{errors.nidNumber.message}</Text>
            )} */}
          </GridItem>
          <GridItem>
            <FormLabel>FB Profile Link</FormLabel>
            <Input type="text" {...register("facebookProfile")} />
          </GridItem>
          <GridItem>
            <FormLabel>FB Page Link</FormLabel>
            <Input type="text" {...register("facebookProfile")} />
          </GridItem>
          <GridItem>
            <FormLabel>Reference ID</FormLabel>
            <Input type="text" {...register("referenceId")} />
          </GridItem>
          <GridItem>
            <FormLabel>Present Address</FormLabel>
            <Input type="text" {...register("presentAddress")} />
          </GridItem>
          <GridItem>
            <FormLabel>
              Permanent Address
              {/* <Text as="span" color={"red.400"}>
                *
              </Text> */}
            </FormLabel>
            <Input
              type="text"
              {...register("permanentAddress", {
                // required: "Permanent Address is required",
              })}
            />
            {/* {errors.permanentAddress && (
              <Text color={"tomato"}>{errors.permanentAddress.message}</Text>
            )} */}
          </GridItem>
          <GridItem>
            <FormLabel>
              Password
              <Text as="span" color={"red.400"}>
                *
              </Text>
            </FormLabel>
            <Input
              type="password"
              {...register("password", {
                required: "Password is required",
              })}
            />
            {errors.password && (
              <Text color={"tomato"}>{errors.password.message}</Text>
            )}
          </GridItem>
          <GridItem>
            <FormLabel>
              Confirm Password
              <Text as="span" color={"red.400"}>
                *
              </Text>
            </FormLabel>
            <Input
              type="password"
              {...register("confirmPassword", {
                required: "Password is required",
                validate: (value) =>
                  value === password || "Passwords do not match",
              })}
            />
            {errors.confirmPassword && (
              <Text color={"tomato"}>{errors.confirmPassword.message}</Text>
            )}
          </GridItem>
          <GridItem>
            <VStack
              bg="gray.100"
              px={5}
              py={3}
              borderRadius="md"
              boxShadow="lg"
              spacing={6}
              w="full"
              maxW="md"
            >
              <Heading size="sm" mb={2}>
                Upload Profile Picture
              </Heading>
              <Box>
                {profilePicture ? (
                  <Image
                    src={profilePicture}
                    alt="Preview"
                    maxW="130px"
                    maxH="130px"
                  />
                ) : (
                  <Text color="gray.500">No image selected</Text>
                )}
              </Box>
              <Input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageUpload(e, setProfilePicture)}
                variant="filled"
              />
            </VStack>
          </GridItem>
          <GridItem>
            <VStack
              bg="gray.100"
              px={5}
              py={3}
              borderRadius="md"
              boxShadow="lg"
              spacing={6}
              w="full"
              maxW="md"
            >
              <Heading size="sm" mb={2}>
                Upload NID (Front side)
              </Heading>
              <Box>
                {nidFront ? (
                  <Image
                    src={nidFront}
                    alt="Preview"
                    maxW="130px"
                    maxH="130px"
                  />
                ) : (
                  <Text color="gray.500">No image selected</Text>
                )}
              </Box>
              <Input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageUpload(e, setNidFront)}
                variant="filled"
              />
            </VStack>
          </GridItem>
          <GridItem>
            <VStack
              bg="gray.100"
              px={5}
              py={3}
              borderRadius="md"
              boxShadow="lg"
              spacing={6}
              w="full"
              maxW="md"
            >
              <Heading size="sm" mb={2}>
                Upload NID (Back side)
              </Heading>
              <Box>
                {nidBack ? (
                  <Image
                    src={nidBack}
                    alt="Preview"
                    maxW="130px"
                    maxH="130px"
                  />
                ) : (
                  <Text color="gray.500">No image selected</Text>
                )}
              </Box>
              <Input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageUpload(e, setNidBack)}
                variant="filled"
              />
            </VStack>
          </GridItem>
        </Grid>
        <Box textAlign={"center"}>
          <Button type="submit" colorScheme="blue" mt={20} mb={5} px={20}>
            Add Translator
          </Button>
        </Box>
      </form>
    </Box>
  );
}
